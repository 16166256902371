import React, { useState } from 'react';

const ExpandableElement = ({
  question,
  answer,
})=> {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleFAQ = () => {
    var faqlist = document.querySelectorAll('.expand');
    for (let i = 0; i < faqlist.length; i++) {
      const faqItem = faqlist[i];
      faqItem.classList.remove('expand')
    }
    setIsExpanded(!isExpanded);
  };
  return (
    <div className={`flex flex-col w-full justify-between items-center cursor-pointer faq-items ${isExpanded ? 'expand' : ''}`} onClick={toggleFAQ}>
      <div className='title w-full flex flex-row justify-between items-center pb-5'>
        <div className=' text-[#5A1DB6] text-xl md:text-[1.5rem] exo-font mb-4'>
          {question}
        </div>
        <div className='arrow'>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 42 43" fill="none">
            <g clip-path="url(#clip0_255_1765)">
            <rect x="42" y="0.5" width="42" height="42" rx="21" transform="rotate(90 42 0.5)" fill="white"/>
            <path d="M21.1667 42.1667C15.6413 42.1667 10.3423 39.9717 6.43527 36.0647C2.52826 32.1577 0.333328 26.8587 0.333328 21.3333C0.333328 18.5975 0.8722 15.8884 1.91917 13.3608C2.96614 10.8331 4.50072 8.5365 6.43527 6.60194C10.3423 2.69493 15.6413 0.5 21.1667 0.5C26.692 0.5 31.991 2.69493 35.8981 6.60194C39.8051 10.509 42 15.808 42 21.3333C42 24.0692 41.4611 26.7783 40.4142 29.3059C39.3672 31.8335 37.8326 34.1302 35.8981 36.0647C33.9635 37.9993 31.6669 39.5339 29.1392 40.5808C26.6116 41.6278 23.9025 42.1667 21.1667 42.1667ZM8.66666 17.1667L21.1667 29.6667L33.6667 17.1667L30.75 14.25L21.1667 23.8333L11.5833 14.25L8.66666 17.1667Z" fill="#603998"/>
            </g>
            <defs>
            <clipPath id="clip0_255_1765">
            <rect x="42" y="0.5" width="42" height="42" rx="21" transform="rotate(90 42 0.5)" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className='content exo-font !pb-4'>
        {answer}
      </div>
    </div>
  )
}

export default ExpandableElement